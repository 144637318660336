
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import s1 from './assets/in.png';
import lo from './assets/lo.png';
import s2 from './assets/s2.png';

import { FaFacebook, FaMapMarkerAlt, FaPhoneAlt, FaHeart } from "react-icons/fa";


import styled, { keyframes } from 'styled-components';
import { bounce } from 'react-animations';



const bounceAnimation = keyframes`${bounce}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

function Main() {
  return (
    <div>
    <Container fluid   style={{
        backgroundColor: '#ffc823', Height:'100%',padding:'50px',
 }}>
       
          <Row>
        <Col xs={12} md={8} lg={4} >
        <center> <img src={s2} style={{
            width: "80%",
            marginTop:"10px",
            horizondalAlign: "center"
            }} />    
 </center>       
           
        </Col>
          <Col xs={12} md={8} lg={4} >
            <center> <img src={lo} style={{
              width: "60%",
              horizondalAlign: "center"
            }} />   <h3 style={{ color:"#000", fontSize:"0.8em"}}>Naspro</h3>
            </center>

          </Col>
         
          <Col xs={12} md={8} lg={4}  ><img src={s1} style={{
            width: "80%",
            marginTop: "10px",
            horizondalAlign: "center"
          }} /></Col>

      </Row>
        <Row className="Service skewed" >
          <Col xs={12} md={8} lg={12} >
            <center>
              <h3>Our Services</h3>
            </center>
          </Col>

          <Col xs={12} md={8} lg={3} >
            <p>  Service</p>
           </Col>
          <Col xs={12} md={8} lg={3} >
            <p>  Painting</p>
          </Col>
          <Col xs={12} md={8} lg={3} >
            <p>  Accessories</p>
          </Col>
          <Col xs={12} md={8} lg={3} >
            <p>  Decorations </p>
          </Col>                    
        </Row>
        <Row style={{ marginTop: "40px", marginBottom:"20px" }} >

        <Col xs={12} md={8} lg={4} >
            <center>  <FaPhoneAlt/>
            <p>24/7 service Call us: <br />
                90955 10555 ,  90951 20555</p>
                </center> 
        </Col>
          <Col xs={12} md={8} lg={4} >
            <center>  <a href="https://g.page/nasproindia?share"> <FaMapMarkerAlt /> </a>
              <p>199 /27 , Palani road, <br /> Palapampatti  Udumalpet</p>
            </center>
          </Col>
          <Col xs={12} md={8} lg={4} > 
            <center>
              <a href="https://www.facebook.com/naspro.coimbatore/"> <FaFacebook /> </a>
            <p>Lets's Be Social,  <br /> Please get Connected</p>
            </center> 
          </Col>
          <Col xs={12} md={8} lg={12} style={{ marginTop: "50px"  }}>
            <center>
              <a href="https://www.venktertech.com/" style={{ color: "#000", textDecoration: "none" }}> VENKTER    Made with <FaHeart/> </a>
             </center>
          </Col>
      </Row>
    </Container>
     

    </div>
    

   
  

    
    
  );
}

export default Main;