import Mains from './slider';

function App() {
  return (
    <div className="App">
       <Mains/>

        
    </div>
  );
}

export default App;
